
/* Picker size */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 43px;
  font-size: 1rem !important;
  font-family: "NotoSansCJKkr" !important;
  padding-bottom: 5px;
}

/* TimePicker modal font size */
.MuiButtonBase-root {
  font-size: 14px !important;
}

/* TimePicker modal header font size */
.MuiTypography-root {
  font-size: 14px !important;
}

.custom-event-0 {
  background-color: darkgreen !important;
}

.custom-event-1 {
  background-color: #d3850f !important;
}

.custom-event-2 {
  background-color: pink !important;
}

.custom-event-3 {
  background-color: #ffc500 !important;
}

.custom-event-4 {
  background-color: #3b99fc !important;
}

/*Full Calendar*/

.selected-date-cell {
  box-shadow: 0 0 0 0.1rem grey inset !important;
  border-radius: 5px !important;
}

.fc .fc-daygrid-day-frame{
  height: 7rem;
  max-height: 3rem;
  overflow: hidden;
  box-sizing: border-box;
}

.collapsed-event {
  font-size: 0 !important;
  height: 0.4rem !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.fc-col-header .fc-day-sun {
  color: red;
}

.fc-col-header .fc-day-sat {
  color: blue;
}

.fc-event .fc-event-main {
  height: 0.85rem;
  font-size: 0.65rem;
  border-radius: 0 !important;
}

.fc-more-popover {
  pointer-events: none;
  visibility: hidden;
}

:root{
  --fc-border-color: none;
  --fc-today-bg-color: none;
  --fc-event-border-color: none;
  --fc-event-selected-overlay-color: none;
}

.fc-daygrid-event{
  border-radius: 0;
}

.share-header {
  position: sticky;
  top: 0;
  right: auto;
  bottom: auto;
  left: auto;
  height: 3.625rem;
  display: flex
;
  padding: 1rem;
  background-color: #ffffff;
}